.filter-button {
  border: none;
  background-color: white;
  padding: 1px;
  margin-right: 10px;
  box-sizing: border-box;
  flex-grow: 1;
}

.active-true {
  border: 1px solid black;
  box-sizing: border-box;
}

hi {
  color: hsl(210, 10%, 23%);
}

.filter-button-area {
  display: flex;
}

.filter-inner {
  background-color: white;
  width: 100%;
  padding: 8px 14px;
  box-sizing: border-box;
}

@media (max-width: 770px) {
  .filter-button {
    /* margin-right: 5px;
    margin-left: 5px; */
  }

  .filter-button-area {
    /* margin-right: -5px;
    margin-left: -5px; */
  }
}
