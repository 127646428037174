.photo {
  grid-area: photo;
  grid-row-start: 0;
  margin-top: 20px;
  width: 60%;
  margin: auto;
}

.photo > img {
  object-fit: cover;
  width: 100%;
  -webkit-clip-path: circle(50% at 50% 50%);
  clip-path: circle(50% at 50% 50%);
  margin-top: 50px;
}
#info-text {
  grid-row-start: 2;
  width: 85%;
  margin: auto;
  margin-top: 50px;
}
#name-header {
  font-weight: 700;
}
#title-box {
  text-align: center;
  font-size: 1.2em;
}
.resume-header {
  font-size: 1.2em;
  padding-left: 5px;
}

.social-links {
  width: 50%;
  margin: auto;
  display: flex;
  justify-content: space-around;
  margin-top: 50px;
}

@media (max-width: 770px) {
  .social-links {
    width: 100%;
  }
}
