.loading-notice {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 10px;
    align-items: center;
}

.loading-notice-spinner {
    height: 30px;
    display: inline-block;
    align-self: center;
}
