:root {
  --box-shadow-initial: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  --main-background-color: #ededef;
  --animation-interval: 1s;
}
html {
  height: 100%;
  font-family: "Open Sans", sans-serif;
  /* font-weight: 100; */
  -webkit-font-smoothing: antialiased;
}
body {
  width: 100%;
  min-height: 100%;
  margin: 0px;
  background-color: var(--main-background-color);
}

form {
  font-size: 1rem;
}

button {
  font-size: 1rem;
  background-color: white;
  border-radius: 5px;
  cursor: pointer;
  margin: 0px 0px 0px 0px;
  box-sizing: border-box;
}

input {
  height: 1.5em;
  font-size: 1rem;
}

input,
textarea {
  padding: 5px 7px;
}

textarea {
  font-family: inherit;
  font-size: 1rem;
  border-radius: 5px;
  resize: vertical;
}

/*General styling*/

.nobreak {
  white-space: nowrap;
}
td:nth-child(1) {
  vertical-align: top;
  padding-left: 5px;
}
td {
  padding: 10px;
}
button:focus {
  outline: 0;
}
.bold {
  font-weight: 600;
}
.align-right {
  text-align: right;
}
.align-top {
  vertical-align: top;
}
table ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 20px;
}

/*Wraps the whole site*/

#wrapper {
  width: 100%;
  margin: auto;
  height: 100%;
  padding: 80px 0px 40px 0px;
  box-sizing: border-box;
}

#grid-wrapper {
  display: grid;
  grid-template-columns: 3fr 5fr;
}

/*LINKS*/

a:link {
  text-decoration: none;
  color: inherit;
}
.user-added-text a {
  border-bottom: 1px dotted rgba(162, 162, 162, 0.8);
}
a:visited {
  text-decoration: none;
  color: inherit;
}
a:hover {
  text-decoration: none;
}
a:active {
  text-decoration: underline;
}

/*ANIMATIONS*/

.fall-down {
  animation-duration: var(--animation-interval);
  animation-name: fallDown;
  animation-fill-mode: forwards;
}
@keyframes fallDown {
  from {
    margin-top: -20px;
  }
  to {
    margin-top: 0px;
  }
}

@media (max-width: 770px) {
  /* Switch to single column layout */
  #grid-wrapper {
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
    row-gap: 10px;
  }
  #projects-area {
    width: 90%;
    margin: auto;
    margin-top: 20px;
  }
  #site-header {
    display: none;
  }
  .main-header {
    grid-template-columns: 100%;
  }
  .menu-items {
    justify-content: space-around;
  }
}
