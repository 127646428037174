/*Side bar with photo and text*/

#info-area {
  width: auto;
  box-sizing: border-box;
  margin: 10px 20px 0px 20px;
  grid-column-start: 1;
  background-color: white;
  height: 100%;
  padding-bottom: 20px;
}
