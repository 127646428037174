/*CARDS*/

.card {
  transition: 0.2s;
  width: 100%;
  margin-bottom: 4%;
  display: inline-block;
  font-family: "Open Sans", sans-serif;
  font-weight: 100;
  font-size: 0.9em;
  background-color: white;
}
.card a {
  height: 100%;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.item-image {
  width: 100%;
}
.card h4 {
  font-weight: 600;
}
.container {
  padding: 2px 16px;
}
