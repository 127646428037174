#contact-form-wrapper {
  margin: auto;
}

.form-group {
  margin: 15px 0px;
  display: flex;
  flex-direction: column;
}

form {
  width: 50%;
  margin: auto;
  padding-bottom: 50px;
}

#message-textarea {
  height: 280px;
}

label {
  margin-bottom: 5px;
}

.btn-submit {
  display: block;
  margin: auto;
  margin-top: 20px;
}

.alert-message {
  color: rgba(0, 0, 0, 0.7);
  border-radius: 5px;
  padding: 20px;
  font-weight: bold;
}

.alert-message.success-false {
  background-color: hsla(30, 86%, 59%, 0.925);
}

.alert-message.success-true {
  background-color: hsla(140, 100%, 50%, 0.502);
}

@media (max-width: 770px) {
  form {
    width: 100%;
  }
}
