.item-wrapper {
  padding: 10px 20px 50px 20px;
  width: 50%;
  margin: auto;
  box-sizing: border-box;
  background-color: white;
}

.item-wrapper table {
  max-width: 90%;
}

@media (max-width: 770px) {
  .item-wrapper {
    width: 100%;
  }
}
