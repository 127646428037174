.main-header {
  height: 70px;
  position: fixed;
  z-index: 2;
  width: 100%;
  color: white;
  justify-content: space-between;
  align-items: center;
  display: flex;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
}
.menu-items {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.menu-item {
  margin-right: 20px;
}
#site-header {
  display: block;
  margin-left: 20px;
  cursor: pointer;
}
