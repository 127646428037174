#projects-area {
  margin-top: 20px;
  font-weight: 700;
  font-size: 1.1em;
  width: 100%;
  margin-left: 5px;
  height: auto;

  /* Two col layout */
  overflow: hidden;
  margin-bottom: -1.875em;
  -webkit-column-count: 2;
  -webkit-column-gap: 1.5em;
  -webkit-column-fill: auto;
  -moz-column-count: 2;
  -moz-column-gap: 1.5em;
  -moz-column-fill: auto;
  column-count: 2;
  column-gap: 1.5em;
  column-fill: balance;
}

.item {
  background-color: #169fe6;
  margin-bottom: 1.875em;
  -webkit-column-break-inside: avoid;
  -moz-column-break-inside: avoid;
  column-break-inside: avoid;
}

@media only screen and (max-width: 1000px) {
  /*Switch to single column for projects*/
  .theme-group {
    grid-template-columns: 100%;
    grid-column-gap: 0%;
  }

  #projects-area {
    /* Two col layout */
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    margin-left: 0px;
  }

  #item-area {
    box-sizing: border-box;
  }
}
