.art-card-description {
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 1;
  position: absolute;
  opacity: 0;
  color: black;
  display: flex;
  align-items: flex-end;
  font-style: italic;
  font-weight: 200;
}

.art-card-description-inner {
  width: 100%;
  margin-bottom: 7px;
}

.art-card-description .card-title {
  margin: 0px;
  text-align: center;
}

.art-card-description:hover {
  opacity: 1;
}
